<div
  class="card"
  style="display: flex; justify-content: space-between; margin-bottom: 10px"
>
  <div>
    <p class="poppins-500" style="font-size: 26px">+ Create KPI</p>
    <p class="poppins-400 font-14">
      Create KPI and Metrics with 100% total values.
    </p>
  </div>
  <div style="display: flex; gap: 10px">
    <button class="p-button p-button-outlined">
      <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor">
        <path
          d="M16.7605 16.2498H15.7199V17.2915C15.7199 17.5845 15.6196 17.8313 15.419 18.0321C15.2185 18.2328 14.9719 18.3332 14.6792 18.3332H12.5979C12.3161 18.3332 12.0722 18.2328 11.8662 18.0321C11.6602 17.8313 11.5573 17.5845 11.5573 17.2915V16.2498H4.27271C3.69818 16.2498 3.20767 16.0464 2.80116 15.6395C2.39466 15.2326 2.19141 14.7416 2.19141 14.1665V5.83317C2.19141 5.55105 2.29439 5.30691 2.50035 5.10075C2.70631 4.89459 2.95021 4.7915 3.23206 4.7915H11.0369L11.7849 3.42432C11.8825 3.25071 12.0451 3.08794 12.2727 2.93604C12.5003 2.78413 12.728 2.70817 12.9556 2.70817H16.8581C17.1182 2.70817 17.3621 2.77599 17.5898 2.91162C17.8174 3.04725 17.9692 3.21815 18.0451 3.42432C18.5762 4.15131 18.8418 4.60704 18.8418 4.7915V14.1665C18.8418 14.7416 18.6386 15.2326 18.2321 15.6395C17.8256 16.0464 17.335 16.2498 16.7605 16.2498ZM16.7605 12.0832H14.6792V9.99984C14.6792 9.71772 14.5762 9.47358 14.3703 9.26742C14.1643 9.06125 13.9177 8.95817 13.6304 8.95817C13.3432 8.95817 13.0993 9.06125 12.8987 9.26742C12.6982 9.47358 12.5979 9.71772 12.5979 9.99984V12.0832H10.5166C10.2239 12.0832 9.97731 12.1863 9.77677 12.3924C9.57623 12.5986 9.47596 12.8454 9.47596 13.133C9.47596 13.4205 9.57623 13.6647 9.77677 13.8654C9.97731 14.0661 10.2239 14.1665 10.5166 14.1665H12.5979V16.2498C12.5979 16.5428 12.6982 16.7897 12.8987 16.9904C13.0993 17.1911 13.3432 17.2915 13.6304 17.2915C13.9177 17.2915 14.1643 17.1911 14.3703 16.9904C14.5762 16.7897 14.6792 16.5428 14.6792 16.2498V14.1665H16.7605C17.0424 14.1665 17.2863 14.0661 17.4922 13.8654C17.6982 13.6647 17.8012 13.4205 17.8012 13.133C17.8012 12.8454 17.6982 12.5986 17.4922 12.3924C17.2863 12.1863 17.0424 12.0832 16.7605 12.0832ZM2.93937 2.38265C3.01525 2.17649 3.16702 2.00559 3.39466 1.86995C3.6223 1.73432 3.86078 1.6665 4.11011 1.6665H8.01254C8.27271 1.6665 8.51661 1.73432 8.74425 1.86995C8.97189 2.00559 9.12908 2.17649 9.2158 2.38265L9.99628 3.74984H2.19141L2.93937 2.38265Z"
          fill="currentColor"
        />
      </svg>
      <span style="margin-left: 2px">Save</span>
    </button>
  </div>
</div>

<div style="height: 85%" class="card flex flex-row overflow-hidden">
  <div style="width: 50%" class="flex flex-column">
    <div style="width: 90%" class="flex justify-content-between mb-1">
      <p-dropdown
        [options]="roles"
        [(ngModel)]="selectedRoles"
        optionLabel="name"
        [showClear]="true"
        placeholder="Select Role"
        [loading]="!roles.length"
        [disabled]="!roles.length || isEdit"
        (ngModelChange)="onRoleSelect()"
      />

      <div class="flex gap-2">
        @if (!isReady && roles.length) {
        <button
          class="p-button p-button-outlined"
          (click)="showKPIDialog(true)"
        >
          <i class="pi pi-plus" style="color: currentColor"></i>
          <span style="margin-left: 8px">Add New KPI</span>
        </button>
        } @if(kpis.length){
        <button
          class="p-button"
          [ngClass]="isReady ? 'p-button-ready' : 'p-button-outlined'"
          (click)="updateKpiWeights(roleId)"
        >
          <i class="pi pi-check-circle" style="color: currentColor"></i>
          <span style="margin-left: 8px">READY TO USE</span>
        </button>
        }
      </div>
    </div>

    <p-divider />
    <div
      class="overflow-auto"
      cdkDropList
      (cdkDropListDropped)="kpiDrop($event)"
    >
      <div
        [ngClass]="kpi.toggle ? 'mb-3' : ''"
        *ngFor="let kpi of kpis; index as kpiIndex"
        cdkDrag
      >
        <div
          style="cursor: pointer"
          class="toggle-header"
          [ngClass]="kpi.toggle ? '' : 'toggle-header-hidden'"
          (click)="onKpiToggle(kpi)"
          cdkDragHandle
        >
          <div
            style="display: flex; align-items: center; justify-items: center"
          >
            <p style="color: #ffffff; font-weight: 400; font-size: 14px">
              {{ kpi.name }}
            </p>
          </div>

          <div style="display: flex; flex-direction: row; gap: 16px">
            <div
              style="display: flex; align-items: center; justify-items: center"
            >
              <p style="color: #ffffff; font-weight: 400; font-size: 14px">
                {{ kpi.weight }}%
              </p>
            </div>

            <div
              style="display: flex; align-items: center; justify-items: center"
            >
              <i
                class="pi pi-chevron-down"
                [ngClass]="kpi.toggle ? '' : 'rotated'"
              ></i>
            </div>
            <div
              style="display: flex; align-items: center; justify-items: center"
            >
              <p-menu #menu [model]="items" [popup]="true"> </p-menu>
              <i
                class="pi pi-ellipsis-v"
                style="font-size: 1rem; color: #ffffff"
                (click)="onMenuClick($event, menu)"
              ></i>
            </div>
          </div>
        </div>
        <div
          class="toggle-content"
          [ngClass]="
            kpi.toggle ? 'toggle-content-show' : 'toggle-content-hidden'
          "
        >
          <div style="display: flex; flex-direction: row; gap: 10px">
            <div style="display: flex; flex-direction: column; width: 80%">
              <p>KPI</p>
              <input
                type="text"
                pInputText
                required
                #kpiName
                [value]="kpi.name"
                [ngClass]="{ disabled: isReady }"
                (focusin)="checkValue(kpiName.value)"
                (focusout)="
                  updateKpi(
                    kpiName.value,
                    kpi.id,
                    { name: kpiName.value },
                    kpiIndex
                  )
                "
              />
            </div>

            <div style="display: flex; flex-direction: column; width: 20%">
              <p>Weight (%)</p>
              <input
                type="text"
                pKeyFilter="int"
                pInputText
                #kpiWeight
                [value]="kpi.weight"
                [ngClass]="{ disabled: isReady }"
                (focusin)="checkValue(kpiWeight.value)"
                (focusout)="
                  updateKpi(
                    kpiWeight.value,
                    kpi.id,
                    {
                      weight: kpiWeight.value
                    },
                    kpiIndex
                  )
                "
              />
            </div>
          </div>

          <!-- Goal -->
          <div
            *ngFor="let goal of kpi.goals; index as goalIndex"
            style="
              background: #fff;
              border: 1px solid lightgray;
              border-radius: 8px;
              padding: 8px 16px 16px 16px;
              margin-top: 10px;
              margin-bottom: 10px;
            "
          >
            <div
              style="display: flex; flex-direction: column; margin-top: 10px"
            >
              <p>Goal</p>
            </div>

            <div
              style="
                display: flex;
                flex-direction: row;
                gap: 10px;
                margin-top: 10px;
              "
            >
              <input
                style="width: 560px"
                type="text"
                pInputText
                #goalName
                [value]="goal.name"
                [ngClass]="{ disabled: isReady }"
                (focusin)="checkValue(goalName.value)"
                (focusout)="
                  updateGoal(goal.id, kpiIndex, goalIndex, goalName.value, {
                    name: goalName.value
                  })
                "
              />
              <button
                class="p-button p-button-outlined inline"
                [ngClass]="{ disabled: isReady }"
                style="width: 145px"
                type="button"
                (click)="showMetricDialog(true, goal.id, kpiIndex, goalIndex)"
              >
                + Add Metric
              </button>
            </div>

            <div cdkDropList (cdkDropListDropped)="metricDrop($event)">
              <app-metric
                *ngFor="let metric of goal.metrics; index as metricIndex"
                [metric]="metric"
                [kpis]="this.kpis"
                [kpiIndex]="kpiIndex"
                [goalIndex]="goalIndex"
                [metricIndex]="metricIndex"
                (index)="onGetIndex($event)"
                [isReady]="isReady"
              />
            </div>
          </div>

          <div style="margin-top: 10px; display: flex">
            <button
              class="p-button p-button-outlined inline w-12"
              [ngClass]="{ disabled: isReady }"
              (click)="showGoalDialog(true, kpi.id, kpiIndex)"
            >
              + Add New Goal
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="flex" style="min-height: 700px; width: 50%; padding: 0px 30px">
    <app-duplicate-template-list
      [roleId]="this.selectedRoles ? this.selectedRoles.code : ''"
    />
  </div>

  <!-- Dialog -->

  <p-dialog
    header="Create New KPI"
    [(visible)]="visibleKPI"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <form #kpiForm="ngForm" (ngSubmit)="onSubmitKpi(kpiForm)">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 16px;
          "
        >
          <div class="flex flex-row gap-3">
            <div style="flex: 2; display: flex; flex-direction: column">
              <p>KPI Name</p>
              <input
                type="text"
                pInputText
                id="kpiName"
                name="kpiName"
                ngModel
                required
              />
            </div>
            <div style="flex: 1; display: flex; flex-direction: column">
              <p>Weight (%)</p>
              <input
                type="text"
                pKeyFilter="int"
                pInputText
                id="kpiWeight"
                name="kpiWeight"
                ngModel
                required
              />
            </div>
          </div>

          <div class="flex flex-column">
            <p>Description</p>
            <textarea
              rows="5"
              cols="30"
              pInputTextarea
              id="kpiDescription"
              name="kpiDescription"
              ngModel
            ></textarea>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin-top: 25px;
            align-items: center;
            justify-content: center;
          "
        >
          <button
            class="p-button p-button-outlined-secondary"
            type="button"
            (click)="showKPIDialog(false)"
          >
            Cancel
          </button>
          <button class="p-button" type="submit">Add</button>
        </div>
      </form>
    </div>
  </p-dialog>

  <p-dialog
    header="Create New Goal"
    [(visible)]="visibleGoal"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <form #goalForm="ngForm" (ngSubmit)="onSubmitGoal(goalForm)">
        <div
          style="
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 16px;
          "
        >
          <div style="flex: 2; display: flex; flex-direction: column">
            <p>Goal Name</p>
            <input
              pInputText
              type="text"
              id="goalName"
              ngModel
              name="goalName"
              required
            />
          </div>
          <div style="flex: 1; display: flex; flex-direction: column">
            <p>Description</p>
            <textarea
              rows="6"
              cols="30"
              pInputTextarea
              id="goalDescription"
              name="goalDescription"
              ngModel
            ></textarea>
          </div>
        </div>
        <div
          style="
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin-top: 25px;
            align-items: center;
            justify-content: center;
          "
        >
          <button
            class="p-button p-button-outlined-secondary"
            type="button"
            (click)="showGoalDialog(false, '', 0)"
          >
            Cancel
          </button>
          <button class="p-button" type="submit">Add</button>
        </div>
      </form>
    </div>
  </p-dialog>

  <p-dialog
    header="Add New Metric"
    [(visible)]="visibleMetric"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <form #metricForm="ngForm" (ngSubmit)="onSubmitMetric(metricForm)">
        <div class="flex flex-column gap-3">
          <div class="flex flex-row gap-2 mt-3">
            <div class="flex flex-column gap-2" style="width: 80%">
              <label for="metricName">Metric Name</label>
              <input
                type="text"
                pInputText
                id="metricName"
                name="metricName"
                ngModel
                required
              />
            </div>
            <div class="flex flex-column gap-2" style="width: 20%">
              <label for="metricWeight">Weight (%)</label>
              <input
                type="text"
                pInputText
                pKeyFilter="int"
                id="metricWeight"
                name="metricWeight"
                ngModel
                required
              />
            </div>
          </div>
          <div class="flex flex-column gap-2">
            <label for="metricDescription">Description</label>
            <textarea
              rows="5"
              cols="30"
              pInputTextarea
              id="metricDescription"
              name="metricDescription"
              ngModel
            ></textarea>
          </div>
          <div class="flex flex-column gap-2" [formGroup]="rangeForm">
            <label>Range Type</label>
            <div formArrayName="range">
              <div
                class="flex flex-row gap-2 mt-1"
                *ngFor="let rangeCtrl of controls; let i = index"
                [formGroupName]="i"
              >
                <input
                  class="text-center bg-blue-700 text-white p-component"
                  style="width: 10%"
                  formControlName="range"
                  type="text"
                  pInputText
                  readonly
                />
                <input
                  style="width: 90%"
                  formControlName="description"
                  type="text"
                  pInputText
                />
              </div>
            </div>
          </div>
          <div class="flex flex-row justify-content-center gap-3 mt-4">
            <button
              class="p-button p-button-outlined-secondary"
              type="button"
              (click)="showMetricDialog(false, '', 0, 0)"
            >
              Cancel
            </button>
            <button class="p-button" type="submit">Add</button>
          </div>
        </div>
      </form>
    </div>
  </p-dialog>

  <p-dialog
    header=""
    [(visible)]="visibleDelete"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        "
      >
        <svg
          width="64"
          height="57"
          viewBox="0 0 64 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M60.2597 57H3.74778C3.09278 56.9978 2.44967 56.8231 1.88192 56.4929C1.31417 56.1628 0.841416 55.6887 0.510334 55.1174C0.178004 54.545 0.00190496 53.8936 1.53688e-05 53.2299C-0.00187423 52.5662 0.170513 51.9139 0.499578 51.3395L28.7726 1.88623C29.0987 1.3123 29.569 0.835622 30.1359 0.504404C30.7028 0.173187 31.3462 -0.000813759 32.001 2.86121e-06C33.3329 2.86121e-06 34.5734 0.722967 35.2367 1.88804L63.4326 51.2163C63.786 51.7887 63.9815 52.4463 63.9988 53.1208C64.016 53.7953 63.8544 54.4622 63.5307 55.0523C63.2069 55.6424 62.7329 56.1342 62.1577 56.4767C61.5826 56.8192 60.9272 56.9999 60.2597 57ZM31.8756 3.69817L3.60796 53.1442L3.74778 53.3779H60.2597C60.3385 53.3779 60.4156 53.3 60.4156 53.2221C60.4031 53.2022 60.3403 53.0482 60.3278 53.0282L32.1301 3.69817H31.8756Z"
            fill="#EC0000"
          />
          <path
            d="M30.259 20.435V36.2007C30.281 36.7312 30.505 37.2325 30.8842 37.6C31.2633 37.9675 31.7683 38.1727 32.2936 38.1727C32.8189 38.1727 33.3239 37.9675 33.703 37.6C34.0822 37.2325 34.3061 36.7312 34.3282 36.2007V20.435C34.3277 19.889 34.1134 19.3655 33.7322 18.9787C33.3509 18.592 32.8337 18.3736 32.2936 18.3712C31.1768 18.3712 30.259 19.3007 30.259 20.435Z"
            fill="#EC0000"
          />
          <path
            d="M32.295 41.0005C31.1764 41.0005 30.2604 41.9174 30.2604 43.0516V44.4305C30.2642 44.9743 30.4799 45.4946 30.8608 45.8786C31.2417 46.2626 31.7571 46.4793 32.295 46.4816C33.4172 46.4816 34.3296 45.5521 34.3296 44.4305V43.0516C34.3296 41.9174 33.4154 41.0005 32.295 41.0005Z"
            fill="#EC0000"
          />
        </svg>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          font-size: 20px;
        "
      >
        <p>Are sure you want to remove?</p>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 16px;
          margin-top: 25px;
          align-items: center;
          justify-content: center;
        "
      >
        <button
          class="p-button p-button-outlined-secondary"
          (click)="onDelete(false)"
          type="button"
        >
          Cancel
        </button>
        <button class="p-button" type="submit">Remove</button>
      </div>
    </div>
  </p-dialog>

  <p-dialog
    header=""
    [(visible)]="visibleReady"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 14px;
        "
      >
        <svg
          width="54"
          height="47"
          viewBox="0 0 64 57"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M60.2597 57H3.74778C3.09278 56.9978 2.44967 56.8231 1.88192 56.4929C1.31417 56.1628 0.841416 55.6887 0.510334 55.1174C0.178004 54.545 0.00190496 53.8936 1.53688e-05 53.2299C-0.00187423 52.5662 0.170513 51.9139 0.499578 51.3395L28.7726 1.88623C29.0987 1.3123 29.569 0.835622 30.1359 0.504404C30.7028 0.173187 31.3462 -0.000813759 32.001 2.86121e-06C33.3329 2.86121e-06 34.5734 0.722967 35.2367 1.88804L63.4326 51.2163C63.786 51.7887 63.9815 52.4463 63.9988 53.1208C64.016 53.7953 63.8544 54.4622 63.5307 55.0523C63.2069 55.6424 62.7329 56.1342 62.1577 56.4767C61.5826 56.8192 60.9272 56.9999 60.2597 57ZM31.8756 3.69817L3.60796 53.1442L3.74778 53.3779H60.2597C60.3385 53.3779 60.4156 53.3 60.4156 53.2221C60.4031 53.2022 60.3403 53.0482 60.3278 53.0282L32.1301 3.69817H31.8756Z"
            fill="#EC0000"
          />
          <path
            d="M30.259 20.435V36.2007C30.281 36.7312 30.505 37.2325 30.8842 37.6C31.2633 37.9675 31.7683 38.1727 32.2936 38.1727C32.8189 38.1727 33.3239 37.9675 33.703 37.6C34.0822 37.2325 34.3061 36.7312 34.3282 36.2007V20.435C34.3277 19.889 34.1134 19.3655 33.7322 18.9787C33.3509 18.592 32.8337 18.3736 32.2936 18.3712C31.1768 18.3712 30.259 19.3007 30.259 20.435Z"
            fill="#EC0000"
          />
          <path
            d="M32.295 41.0005C31.1764 41.0005 30.2604 41.9174 30.2604 43.0516V44.4305C30.2642 44.9743 30.4799 45.4946 30.8608 45.8786C31.2417 46.2626 31.7571 46.4793 32.295 46.4816C33.4172 46.4816 34.3296 45.5521 34.3296 44.4305V43.0516C34.3296 41.9174 33.4154 41.0005 32.295 41.0005Z"
            fill="#EC0000"
          />
        </svg>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          font-size: 20px;
        "
      >
        <p class="font-16 text-center">
          Mark as ready to use will unable to update this form. You want to
          proceed?
        </p>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 16px;
          margin-top: 25px;
          align-items: center;
          justify-content: center;
        "
      >
        <button
          class="p-button p-button-outlined-secondary"
          (click)="showReadyDialog(false)"
          type="button"
        >
          No
        </button>
        <button class="p-button" (click)="updateRole()">Yes</button>
      </div>
    </div>
  </p-dialog>
  <p-toast position="bottom-right" key="br" />
</div>
