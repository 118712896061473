import { Component, OnInit } from '@angular/core';

import { FormArray, FormControl, FormGroup, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { MenuItem, MessageService } from 'primeng/api';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { Kpi, RoleOptions } from 'src/app/services/role/role.model';

import { KpiRequestData } from 'src/app/services/kpi/kpi.model';
import { GoalRequestData } from 'src/app/services/goal/goal.model';
import { MetricRequestData } from 'src/app/services/metric/metric.model';

import { GoalService } from 'src/app/services/goal/goal.service';
import { MetricService } from 'src/app/services/metric/metric.service';
import { KPIService } from 'src/app/services/kpi/kpi.service';
import { RoleService } from 'src/app/services/role/role.service';
import { IconService } from 'src/app/services/icons/icon.service';
import { flatMap } from 'rxjs';

@Component({
  selector: 'app-template-create',
  templateUrl: './template-create.component.html',
  styleUrl: './template-create.component.scss',
  providers: [MessageService],
})
export class TemplateCreateComponent implements OnInit {
  userId = '9be37a95-628c-40cf-8d89-8d2cb48f13cd';
  roleId = '';
  kpiId = '';
  goalId = '';
  kpiIndex!: number;
  goalIndex!: number;
  previousValue: string = '';

  visibleKPI = false;
  visibleGoal = false;
  visibleMetric = false;
  visibleDelete = false;
  visibleReady = false;
  kpiToggle = true;

  rangeForm!: FormGroup;

  roles: RoleOptions[] = [];
  selectedRoles!: RoleOptions;

  clients: RoleOptions[] = [];
  selectedClient!: RoleOptions;

  isReady = false;
  isEdit = false;
  doesntKPI = true;

  metrics: RoleOptions[] = [];
  selectedMetric!: RoleOptions;

  kpis: Kpi[] = [];
  kpiWeights: Kpi[] = [];
  items: MenuItem[] = [
    {
      items: [
        {
          label: 'Duplicate',
          icon: 'pi pi-refresh',
          command: () => {
            this.onDuplicate();
          },
        },
        {
          label: 'Remove',
          icon: 'pi pi-times',
          command: () => {
            this.onDelete(true);
          },
        },
      ],
    },
  ];

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private messageService: MessageService,
    private roleService: RoleService,
    private kpiService: KPIService,
    private goalService: GoalService,
    private metricService: MetricService,
    private iconService: IconService
  ) {}

  ngOnInit() {
    this.roleId = this.activedRoute.snapshot.paramMap.get('id')!;

    if (this.roleId) {
      this.getRole(this.roleId);
      this.isEdit = true;
    }

    this.items;
    this.getAllRoles();
    this.initForm();
  }

  onMenuClick(event: Event, menu: any) {
    event.stopPropagation();
    menu.toggle(event);
  }

  onGetIndex(index: { kpi: number; goal: number }) {
    this.kpiIndex = index.kpi;
    this.goalIndex = index.goal;
  }

  kpiDrop(event: CdkDragDrop<string[]>) {
    if (!this.isReady) {
      moveItemInArray(this.kpis, event.previousIndex, event.currentIndex);

      this.kpis.map((kpi, index) => {
        this.updateKpiSortOrder(kpi.id, {
          sort_order: index + 1,
        });
      });
    }
  }

  metricDrop(event: CdkDragDrop<string[]>) {
    if (!this.isReady) {
      moveItemInArray(
        this.kpis[this.kpiIndex].goals[this.goalIndex].metrics,
        event.previousIndex,
        event.currentIndex
      );

      this.kpis[this.kpiIndex].goals[this.goalIndex].metrics.map(
        (metric, index) => {
          this.metricService
            .updateMetric(metric.id, { sort_order: index + 1 })
            .subscribe({
              next: (responseData) => {},
              error: (error) => {
                this.showBottomRight(
                  `${error.error.message}`,
                  'error',
                  'Error'
                );
              },
            });
        }
      );
    }
  }

  private initForm() {
    let metricRange = new FormArray(
      [5, 4, 3, 2, 1].map(
        (value) =>
          new FormGroup({
            range: new FormControl(value),
            description: new FormControl(''),
          })
      )
    );

    this.rangeForm = new FormGroup({
      range: metricRange,
    });
  }

  get controls() {
    return (<FormArray>this.rangeForm.get('range')).controls;
  }

  onRoleSelect() {
    if (this.selectedRoles) {
      if (!this.isEdit) {
        this.roleId = this.selectedRoles?.code;
        this.getRole(this.selectedRoles?.code);

        this.router.navigate(['templates/create/role'], {
          queryParams: { id: this.selectedRoles.code },
        });
      }
      return;
    }
    this.router.navigate(['templates/create/role']);
    this.kpis = [];
  }

  onDuplicate() {
    console.log('duplicate');
  }

  onDelete(isDeleteVisible: boolean) {
    this.showDeleteDialog(isDeleteVisible);
  }

  showBottomRight(detail: string, severity: string, summary: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      key: 'br',
      life: 3000,
    });
  }

  checkValue(previousValue: string) {
    this.previousValue = previousValue;
  }

  getRole(id: string) {
    this.roleService.getRole(id).subscribe({
      next: (responseData) => {
        this.kpis = [];
        responseData.data.kpi.map((kpi) => {
          this.kpis.push({ ...kpi, toggle: true });
        });
        if (responseData?.data?.status === 'Ready') {
          this.isReady = true;
        }
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  updateKpiWeights(id: string) {
    this.roleService.getRole(id).subscribe({
      next: (responseData) => {
        this.kpiWeights = [];
        responseData.data.kpi.map((kpi) => {
          this.kpiWeights.push({ ...kpi, toggle: true });
        });
        this.checkKpiWeights();
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  checkKpiWeights() {
    // Calculate the sum of the KPI weights
    const kpiWeightSum = this.kpiWeights.reduce(
      (sum: number, kpi: any) => sum + kpi.weight,
      0
    );

    if (kpiWeightSum !== 100) {
      this.showBottomRight('KPI must be 100.', 'error', 'Error');
      return;
    }

    // For each KPI, calculate the sum of metric weights and check if it's 100
    for (let kpi of this.kpiWeights) {
      let metricWeightSum = 0;
      for (let goal of kpi.goals) {
        metricWeightSum += goal.metrics.reduce(
          (sum: number, metric: any) => sum + metric.weight,
          0
        );
      }

      if (metricWeightSum !== 100) {
        this.showBottomRight('Metric must be 100.', 'error', 'Error');
        return;
      }
    }

    this.showReadyDialog(true);
  }

  updateRole() {
    this.roleService.updateRole(this.roleId, { status: 'Ready' }).subscribe({
      next: (responseData) => {
        this.isReady = true;
        this.showReadyDialog(false);
        this.showBottomRight('Mark as ready to use.', 'success', 'Success');
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  getAllRoles() {
    this.roleService.getAllRoles(this.isEdit ? false : true).subscribe({
      next: (responseData) => {
        this.roles = [];
        responseData.data?.map((role) => {
          this.roles.push({ name: role.title, code: role.id });
        });

        this.activedRoute.queryParams.subscribe((params) => {
          const pId = params['id'];
          if (pId || this.roleId) {
            let index = this.roles.findIndex(
              (id) => id.code === pId || id.code === this.roleId
            );
            this.selectedRoles = this.roles[index];
            this.onRoleSelect();
          }
        });
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  createKpi(postData: KpiRequestData, form?: NgForm) {
    this.kpiService.createKPI(postData).subscribe({
      next: (responseData) => {
        this.kpiId = responseData.data.id;
        this.kpis.push({
          id: responseData.data.id,
          name: form?.value.kpiName,
          description: form?.value.kpiDescription,
          weight: form?.value.kpiWeight,
          evaluator: this.userId,
          goals: [],
          toggle: true,
        });

        this.showBottomRight('Created Successfully', 'success', 'Success');
        form?.reset();
        this.visibleKPI = false;
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  updateKpiSortOrder(id: string, data: { sort_order: number }) {
    this.kpiService.updateKPI(id, data).subscribe({
      next: (responseData) => {},
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  updateKpi(currentValue: any, id: string, data: any, index: number) {
    if (this.previousValue !== currentValue && !this.isReady) {
      this.kpiService.updateKPI(id, data).subscribe({
        next: (responseData) => {
          if (data.name) {
            this.kpis[index]['name'] = currentValue;
          }
          if (data.weight) {
            this.kpis[index]['weight'] = currentValue;
          }

          this.showBottomRight('Updated Successfully', 'success', 'Success');
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }

  createGoal(postData: GoalRequestData, form?: NgForm) {
    this.goalService.createGoal(postData).subscribe({
      next: (responseData) => {
        this.goalId = responseData.data.id;
        this.kpis[this.kpiIndex]['goals'].push({
          id: responseData.data.id,
          name: form?.value.goalName,
          description: form?.value.goalDescription,
          metrics: [],
        });

        this.showBottomRight('Created Successfully', 'success', 'Success');
        form?.reset();
        this.visibleGoal = false;
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  updateGoal(
    id: string,
    kpi: number,
    goal: number,
    currentValue: string,
    data: any
  ) {
    if (this.previousValue !== currentValue && !this.isReady) {
      this.goalService.updateGoal(id, data).subscribe({
        next: (responseData) => {
          if (data.name) {
            this.kpis[kpi].goals[goal].name = currentValue;
          }

          this.showBottomRight('Updated Successfully', 'success', 'Success');
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }

  createMetric(postData: MetricRequestData, form?: NgForm) {
    this.metricService.createMetric(postData).subscribe({
      next: (responseData) => {
        this.kpis[this.kpiIndex].goals[this.goalIndex].metrics.push({
          id: responseData.data.id,
          name: form?.value.metricName,
          description: form?.value.metricDescription,
          weight: form?.value.metricWeight,
          range: this.rangeForm?.value.range,
          toggle: true,
        });
        this.showBottomRight('Created Successfully', 'success', 'Success');
        (<FormArray>this.rangeForm.get('range')).clear();
        form?.reset();
        this.visibleMetric = false;
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  showKPIDialog(isKPIVisible: boolean) {
    this.visibleKPI = isKPIVisible;
  }

  showGoalDialog(isGoalVisible: boolean, id: string, kpi: number) {
    this.visibleGoal = isGoalVisible;
    this.kpiId = id;
    this.kpiIndex = kpi;
  }

  showMetricDialog(
    isMetricVisible: boolean,
    id: string,
    kpi: number,
    goal: number
  ) {
    this.initForm();
    this.visibleMetric = isMetricVisible;
    this.goalId = id;
    this.kpiIndex = kpi;
    this.goalIndex = goal;
  }

  showDeleteDialog(isDeleteVisible: boolean) {
    this.visibleDelete = isDeleteVisible;
  }

  showReadyDialog(isReadyVisible: boolean) {
    this.visibleReady = isReadyVisible;
  }

  onKpiToggle(kpi: Kpi) {
    kpi.toggle = !kpi.toggle;
  }

  onSubmitKpi(form: NgForm) {
    this.createKpi(
      {
        name: form.value.kpiName,
        description: form.value.kpiDescription,
        weight: +form.value.kpiWeight,
        role_id: this.selectedRoles?.code,
        evaluator: this.userId,
      },
      form
    );
  }

  onSubmitGoal(form: NgForm) {
    this.createGoal(
      {
        kpi_id: this.kpiId,
        name: form.value.goalName,
        description: form.value.goalDescription,
      },
      form
    );
  }

  onSubmitMetric(form: NgForm) {
    this.createMetric(
      {
        goal_id: this.goalId,
        name: form.value.metricName,
        description: form.value.metricDescription,
        weight: +form.value.metricWeight,
        range: this.rangeForm.value.range,
      },
      form
    );

    console.log(form.value.metricRange);
  }
}
