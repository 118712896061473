<div class="sidebar">
  <div class="sidebar-logo-container">
    <img
      class="sidebar-logo"
      src="assets/layout/images/sidebar/logo.svg"
      alt="Performance Eval Logo"
    />
  </div>
  <nav class="navigation">
    <a routerLink="/evaluation" routerLinkActive="active" class="nav-item">
      <img
        src="assets/layout/images/sidebar/organisation.svg"
        alt="Evaluation"
      />
      <span>Evaluation</span>
    </a>

    <a routerLink="/performance" routerLinkActive="active" class="nav-item">
      <img src="assets/layout/images/sidebar/chart.svg" alt="Performance" />
      <span>Performance</span>
    </a>
    <a routerLink="/templates" routerLinkActive="active" class="nav-item">
      <img src="assets/layout/images/sidebar/templates.svg" alt="Dashboard" />
      <span>Templates</span>
    </a>
    <a routerLink="/client" routerLinkActive="active" class="nav-item">
      <img src="assets/layout/images/sidebar/organisation.svg" alt="Clients" />
      <span>Clients</span>
    </a>
    <a routerLink="/settings" routerLinkActive="active" class="nav-item">
      <img src="assets/layout/images/sidebar/settings.svg" alt="Settings" />
      <span>Settings</span>
    </a>
  </nav>
</div>
