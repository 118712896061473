import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { IconService } from 'src/app/services/icons/icon.service';
import { Metric } from 'src/app/services/role/role.model';

@Component({
  selector: 'app-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss'],
})
export class MetricComponent {
  @Input({ required: true }) metric!: Metric;
  @Input({ required: true }) selectedRange: number = 0;
  @Input({ required: true }) remarks: string = '';
  @Input({ required: true }) isCompleted!: boolean;
  @Output() selected = new EventEmitter<{
    id: string;
    rating: number;
    remarks: string;
  }>();

  isOpen = false;

  iconService = inject(IconService);

  onToggle() {
    this.isOpen = !this.isOpen;
  }

  getScore() {
    const score = (this.selectedRange * this.metric.weight) / 100;
    return score.toFixed(2);
  }

  onSelected(selected: number) {
    this.selectedRange = selected;
    this.selected.emit({
      id: this.metric.id,
      rating: selected,
      remarks: this.remarks,
    });
  }
}
