import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { Kpi, Metric } from 'src/app/services/role/role.model';

import { MenuItem, MessageService } from 'primeng/api';
import { MetricService } from 'src/app/services/metric/metric.service';

@Component({
  selector: 'app-metric',
  templateUrl: './metric.component.html',
  styleUrls: ['./metric.component.scss'],
  providers: [MessageService],
})
export class MetricComponent implements OnInit {
  @Input({ required: true }) kpis!: Kpi[];
  @Input({ required: true }) metric!: Metric;
  @Input({ required: true }) kpiIndex!: number;
  @Input({ required: true }) goalIndex!: number;
  @Input({ required: true }) metricIndex!: number;
  @Input({ required: true }) isReady!: boolean;
  @Output() index = new EventEmitter<{ kpi: number; goal: number }>();

  previousValue: string = '';
  visibleDelete = false;

  items: MenuItem[] = [
    {
      items: [
        {
          label: 'Duplicate',
          icon: 'pi pi-refresh',
          command: () => {
            this.onDuplicate();
          },
        },
        {
          label: 'Remove',
          icon: 'pi pi-times',
          command: () => {
            this.onDelete(true);
          },
        },
      ],
    },
  ];

  metricService = inject(MetricService);
  messageService = inject(MessageService);

  ngOnInit(): void {}

  showBottomRight(detail: string, severity: string, summary: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      key: 'br',
      life: 3000,
    });
  }

  checkValue(previousValue: string) {
    this.previousValue = previousValue;
  }

  onDuplicate() {
    console.log('duplicate');
  }

  onDelete(isDeleteVisible: boolean) {
    this.showDeleteDialog(isDeleteVisible);
  }

  onMetricToggle(metric: any) {
    metric.toggle = !metric.toggle;
  }

  showDeleteDialog(isDeleteVisible: boolean) {
    this.visibleDelete = isDeleteVisible;
  }

  onGetIndex() {
    this.index.emit({
      kpi: this.kpiIndex,
      goal: this.goalIndex,
    });
  }

  updateMetric(
    currentValue: any,
    id: string,
    data: any,
    kpi: number,
    goal: number,
    metric: number,
    range: number
  ) {
    if (this.previousValue !== currentValue && !this.isReady) {
      this.metricService.updateMetric(id, data).subscribe({
        next: (responseData) => {
          if (data.name) {
            this.kpis[kpi].goals[goal].metrics[metric].name = currentValue;
          }
          if (data.weight) {
            this.kpis[kpi].goals[goal].metrics[metric].weight = currentValue;
          }
          if (data.description) {
            this.kpis[kpi].goals[goal].metrics[metric].description =
              currentValue;
          }
          if (data.name) {
            this.kpis[kpi].goals[goal].metrics[metric].range[range] =
              currentValue;
          }
          this.showBottomRight('Updated Successfully', 'success', 'Success');
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }

  onUpdateRange(
    id: string,
    value: string,
    kpiIndex: number,
    goalIndex: number,
    metricIndex: number,
    rangeIndex: number
  ) {
    const { range } = this.kpis[kpiIndex].goals[goalIndex].metrics[metricIndex];
    range[rangeIndex].description = value;
    this.updateMetric(
      value,
      id,
      { range },
      kpiIndex,
      goalIndex,
      metricIndex,
      rangeIndex
    );
  }
}
