<div class="header">
  <div class="container">
    <img
      class="circular-image"
      src="https://primefaces.org/cdn/primeng/images/demo/avatar/amyelsner.png"
      alt="Kazuhira Miller"
    />
    <div>
      <p class="poppins-500 font-16">
        {{ employee?.first_name }}
        {{ employee?.last_name }}
      </p>
      <p class="poppins-500 font-10">
        {{ employee?.role?.title }}
      </p>
    </div>
  </div>

  <div class="container">
    <img
      class="icon"
      src="assets/layout/images/evaluation/frequency.svg"
      alt="Frequency"
    />
    <div class="flex flex-column">
      <p class="poppins-500 font-16">Frequency</p>
      <p class="poppins-500 font-10">Third Month</p>
    </div>
  </div>

  <div class="container">
    <img
      class="icon"
      src="assets/layout/images/evaluation/overall_progress.svg"
      alt="Overall Progress"
    />
    <div class="flex flex-column">
      <p class="poppins-500 font-16">Overall Progress</p>
      <div class="flex flex-row align-items-center gap-1">
        <label class="poppins-500 font-10">50%</label>
        <p-progressBar [value]="50" />
      </div>
    </div>
  </div>

  <div class="container">
    <img
      class="icon"
      src="assets/layout/images/evaluation/evaluation_period.svg"
      alt="Evaluation Period"
    />

    <div class="flex flex-column">
      <p class="poppins-500 font-16">Evaluation Period</p>
      <p class="poppins-500 font-10">
        {{ employee?.latest_evaluation?.start_date?.iso | date : "mediumDate" }}
        -
        {{ employee?.latest_evaluation?.end_date?.iso | date : "mediumDate" }}
      </p>
    </div>
  </div>
</div>

<div
  style="
    background-color: white;
    display: flex;
    flex-direction: column;
    height: 88%;
    overflow: hidden;
    width: 100%;
    padding: 20px 260px 30px 260px;
    gap: 16px;
  "
>
  <div
    style="
      align-items: center;
      border-bottom: 1px solid lightgray;
      background-color: white;
      display: flex;
      gap: 16px;
      height: 90px;
      width: 100%;
    "
  >
    <div
      class="tab-menu"
      *ngFor="let k of kpi; index as i"
      [ngClass]="{ active: activeTabName === kpi[i].name }"
      (click)="activeTab(kpi[i].name, kpi[i].id)"
    >
      <p>{{ kpi[i].name }} ({{ kpi[i].weight }}%)</p>
    </div>

    <div class="flex flex-row justify-content-center align-items-center gap-2">
      <p class="poppins-500 font-13">Start Date:</p>
      <p-calendar
        [(ngModel)]="startDate"
        [ngClass]="evaluationStatus === 'completed' ? 'disabled' : ''"
      />
    </div>

    <div class="flex flex-row justify-content-center align-items-center gap-2">
      <p class="poppins-500 font-13">End Date:</p>
      <p-calendar
        [(ngModel)]="endDate"
        [ngClass]="evaluationStatus === 'completed' ? 'disabled' : ''"
      />
    </div>

    <p class="poppins-500 font-13">
      Overall Rating: {{ overAllRating.toFixed(1) }}
    </p>

    <button
      class="btn-complete"
      (click)="onMarkAsComplete()"
      [ngClass]="{ completed: status === 'COMPLETED' }"
    >
      <span
        class="flex flex-row justify-content-center align-items-center gap-2"
      >
        @if(status === "COMPLETED") {
        <i class="pi pi-check-circle" style="color: currentColor"></i>
        }
        <p class="poppins-500 font-12">
          {{ status }}
        </p>
      </span>
    </button>
  </div>

  <div
    style="
      display: flex;
      flex-direction: column;
      height: 60vh;
      overflow: auto;
      gap: 16px;
    "
  >
    <div
      class="flex flex-column gap-3"
      *ngFor="let goal of kpi[index]?.goals; index as goalIndex"
    >
      <p class="poppins-500 font-15 uppercase" style="color: #1f3062">
        {{ goal.name }}
      </p>
      <app-metric
        *ngFor="let metric of goal.metrics; index as metricIndex"
        [metric]="metric"
        [selectedRange]="getMetricRating(index, goalIndex, metricIndex)"
        [remarks]="getMetricRemarks(index, goalIndex, metricIndex)"
        (selected)="addMetric($event)"
        [isCompleted]="evaluationStatus === 'completed'"
      />
    </div>

    <app-comment
      [commentGroupName]="'Summary Comment'"
      [textArea1]="businessGoals"
      [textArea2]="personalGoals"
      (comments)="getSummaryComment($event)"
      [isCompleted]="evaluationStatus === 'completed'"
    />
    <app-comment
      [commentGroupName]="'Overall Comment'"
      [textArea1]="employeeStrengths"
      [textArea2]="opportunitiesForDevelopment"
      (comments)="getOverAllComment($event)"
      [isCompleted]="evaluationStatus === 'completed'"
    />
  </div>

  @if(status !== "COMPLETED"){
  <button class="poppins-500 font-12 btn-submit" (click)="onSubmit()">
    Submit
  </button>
  }

  <p-dialog
    header=""
    [(visible)]="visibleComplete"
    [modal]="true"
    [style]="{ width: '25vw' }"
    [draggable]="false"
    [resizable]="false"
  >
    <div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 14px;
        "
      >
        <i
          class="pi pi-check-circle"
          style="color: #98bb52; font-size: 60px"
        ></i>
      </div>
      <div
        style="
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
          font-size: 20px;
        "
      >
        <p class="font-16 text-center">
          Are sure you want to mark as complete?
        </p>
      </div>
      <div
        style="
          display: flex;
          flex-direction: row;
          gap: 16px;
          margin-top: 25px;
          align-items: center;
          justify-content: center;
        "
      >
        <button
          class="p-button p-button-outlined-secondary"
          (click)="showDialog(false)"
          type="button"
        >
          No
        </button>
        <button class="p-button" (click)="onComplete()">Yes</button>
      </div>
    </div>
  </p-dialog>
</div>

<p-toast position="bottom-right" key="br" />
