<div class="main">
  <div class="flex flex-row w-full align-items-center justify-content-between">
    <p class="poppins-500 font-15" style="color: #1f3062">
      {{ commentGroupName }}
    </p>
    <div class="btn-toggle" (click)="onToggle()">
      <i class="pi pi-chevron-down" [ngClass]="{ rotated: !isOpen }"></i>
    </div>
  </div>
  <div class="hidden flex-column gap-3" [ngClass]="{ flex: isOpen }">
    <p class="poppins-300 font-12" style="color: #1f3062">
      *
      {{
        commentGroupName === "Summary Comment"
          ? "Business Goals"
          : "Employee Strengths"
      }}
    </p>
    <textarea
      class="w-full"
      rows="5"
      cols="30"
      [(ngModel)]="textArea1"
      [ngClass]="{ disabled: isCompleted }"
      (ngModelChange)="getValue()"
      pInputTextarea
    ></textarea>
    <p class="poppins-300 font-12" style="color: #1f3062">
      *
      {{
        commentGroupName === "Summary Comment"
          ? "Personal Goals"
          : "Opportunities for Development"
      }}
    </p>
    <textarea
      class="w-full"
      rows="5"
      cols="30"
      [(ngModel)]="textArea2"
      [ngClass]="{ disabled: isCompleted }"
      (ngModelChange)="getValue()"
      pInputTextarea
    ></textarea>
  </div>
</div>
