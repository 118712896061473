import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';

import {
  EvaluationResponseData,
  Metric,
  MetricRating,
} from 'src/app/services/evaluation/evaluation.model';
import { Kpi } from 'src/app/services/role/role.model';

import { EvaluationService } from 'src/app/services/evaluation/evaluation.service';
import { RoleService } from 'src/app/services/role/role.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';

@Component({
  selector: 'app-template-read',
  templateUrl: './template-read.component.html',
  styleUrl: './template-read.component.scss',
  providers: [MessageService],
})
export class TemplateReadComponent implements OnInit {
  activeTabName = 'People';
  toggle = false;
  visibleComplete = false;

  isReady = false;

  kpi: Kpi[] = [];
  metrics: Metric[] = [];
  metricRatings: MetricRating[] = [];

  evaluationKpi!: EvaluationResponseData;

  index = 0;

  businessGoals = '';
  personalGoals = '';
  employeeStrengths = '';
  opportunitiesForDevelopment = '';

  roleService = inject(RoleService);
  employeeService = inject(EmployeeService);
  evaluationService = inject(EvaluationService);
  messageService = inject(MessageService);
  activedRoute = inject(ActivatedRoute);

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getRole();
  }

  showBottomRight(detail: string, severity: string, summary: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      key: 'br',
      life: 3000,
    });
  }

  activeTab(tabName: string, id: string) {
    this.activeTabName = tabName;
    this.index = this.kpi.findIndex((kpi) => kpi.id === id);
  }

  onToggle(toggle: boolean) {
    this.toggle = !toggle;
  }

  addMetric(metric: Metric) {
    let index = this.metrics.findIndex((m) => m.id === metric.id);
    if (index === -1) {
      this.metrics.push(metric);
    } else {
      this.metrics[index] = metric;
    }
  }

  getMetricRating(
    index: number,
    goalIndex: number,
    metricIndex: number
  ): number {
    return this.evaluationKpi
      ? +this.evaluationKpi.role.kpi[index].goals[goalIndex].metrics[
          metricIndex
        ].rating
      : 0;
  }

  getMetricRemarks(
    index: number,
    goalIndex: number,
    metricIndex: number
  ): string {
    return this.evaluationKpi
      ? this.evaluationKpi.role.kpi[index].goals[goalIndex].metrics[metricIndex]
          .remarks
      : '';
  }

  getSummaryComment(comments: { comment1: string; comment2: string }) {
    this.businessGoals = comments.comment1;
    this.personalGoals = comments.comment2;
  }
  getOverAllComment(comments: { comment1: string; comment2: string }) {
    this.employeeStrengths = comments.comment1;
    this.opportunitiesForDevelopment = comments.comment2;
  }

  getRole() {
    const roleId = this.route.snapshot.paramMap.get('id');

    if (roleId) {
      this.roleService.getRole(roleId).subscribe({
        next: (responseData) => {
          const { data } = responseData;
          this.kpi = data.kpi;
          if (data.status === 'Ready') {
            this.isReady = true;
          }
          console.log('success');
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }
}
