<div class="layout-wrapper">
  <div class="layout-sidebar-container">
    <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main">
    <div class="app-topbar">
      <app-topbar></app-topbar>
    </div>
    <div class="layout-main-outlet">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
