import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MetricResponseData, MetricRequestData } from './metric.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../api/environment';

@Injectable({ providedIn: 'root' })
export class MetricService {
  private apiUrl = `${environment.apiUrl}/metric`;

  constructor(private http: HttpClient) {}

  createMetric(data: MetricRequestData): Observable<MetricResponseData> {
    return this.http.post<MetricResponseData>(`${this.apiUrl}`, data);
  }

  updateMetric(id: string, data: any): Observable<MetricResponseData> {
    return this.http.patch<MetricResponseData>(`${this.apiUrl}/${id}`, data);
  }
}
