import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CreatedEvaluationResponse,
  EvaluationData,
  GetEvaluationResponse,
} from './evaluation.model';
import { Observable } from 'rxjs';
import { environment } from '../api/environment';

@Injectable({ providedIn: 'root' })
export class EvaluationService {
  private apiUrl = `${environment.apiUrl}/evaluation`;

  constructor(private http: HttpClient) {}

  createEvaluation(
    data: EvaluationData
  ): Observable<CreatedEvaluationResponse> {
    return this.http.put<CreatedEvaluationResponse>(`${this.apiUrl}`, data);
  }

  getEvaluation(id: string): Observable<GetEvaluationResponse> {
    return this.http.get<GetEvaluationResponse>(`${this.apiUrl}/${id}`);
  }
}
