<div class="container" [ngClass]="{ active: isOpen }">
  <div style="display: flex; flex-direction: row; gap: 100px">
    <div class="metric-info">
      <p class="poppins-500 font-15" style="color: #1f3062">
        {{ metric.name }}
      </p>
      <p class="poppins-500 font-12">
        {{ metric.description }}
      </p>
    </div>

    <div class="info-container">
      <div class="info poppins-500 font-12">
        <p>SCORE</p>
        <p>{{ getScore() }}</p>
      </div>

      <div class="info poppins-500 font-12">
        <p>WEIGHT</p>
        <p>{{ metric.weight }}%</p>
      </div>

      <div class="info poppins-500 font-12">
        <p>COMMENTS</p>
        <mat-icon
          style="width: 13px; height: 13px; margin-top: 3px"
          svgIcon="dot_comment"
        />
      </div>

      <div class="btn-toggle" (click)="onToggle()">
        <i class="pi pi-chevron-down" [ngClass]="{ rotated: !isOpen }"></i>
      </div>
    </div>
  </div>

  <div class="flex-row w-full gap-3" [ngClass]="isOpen ? 'flex' : 'toggled'">
    <div
      class="range disabled"
      [ngClass]="{ positive: selectedRange === 5, disabled: isCompleted }"
      (click)="onSelected(5)"
    >
      <mat-icon svgIcon="exceptional" />
      <p>5 — Exceptional</p>
      <mat-icon
        style="width: 13px; height: 13px"
        [svgIcon]="selectedRange === 5 ? 'dot_active' : 'dot'"
      />
    </div>

    <div
      class="range"
      [ngClass]="{ positive: selectedRange === 4, disabled: isCompleted }"
      (click)="onSelected(4)"
    >
      <mat-icon svgIcon="above_average" />
      <p>4 — Above Average</p>
      <mat-icon
        style="width: 13px; height: 13px"
        [svgIcon]="selectedRange === 4 ? 'dot_active' : 'dot'"
      />
    </div>

    <div
      class="range"
      [ngClass]="{ positive: selectedRange === 3, disabled: isCompleted }"
      (click)="onSelected(3)"
    >
      <mat-icon svgIcon="average" />
      <p>3 — Average</p>
      <mat-icon
        style="width: 13px; height: 13px"
        [svgIcon]="selectedRange === 3 ? 'dot_active' : 'dot'"
      />
    </div>

    <div
      class="range"
      [ngClass]="{ negative: selectedRange === 2, disabled: isCompleted }"
      (click)="onSelected(2)"
    >
      <mat-icon svgIcon="below_average" />
      <p>2 — Below Average</p>
      <mat-icon
        style="width: 13px; height: 13px"
        [svgIcon]="selectedRange === 2 ? 'dot_active' : 'dot'"
      />
    </div>

    <div
      class="range"
      [ngClass]="{ negative: selectedRange === 1, disabled: isCompleted }"
      (click)="onSelected(1)"
    >
      <mat-icon svgIcon="unacceptable" />
      <p>1 — Unacceptable</p>
      <mat-icon
        style="width: 13px; height: 13px"
        [svgIcon]="selectedRange === 1 ? 'dot_active' : 'dot'"
      />
    </div>
  </div>

  <div class="flex-row w-full gap-3" [ngClass]="isOpen ? 'flex' : 'toggled'">
    <div style="width: 70%">
      <p class="poppins-500 font-13">
        Previous Score: 5.00 | Target: 3 = 2 missed opportunities
      </p>

      <div
        style="
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 5px;
        "
      >
        <p class="poppins-500 font-15">Stewart, Mike Cruz Comments</p>
        <mat-icon svgIcon="comment" style="width: 14px; height: 14px" />
      </div>

      <textarea
        class="w-full mt-3"
        rows="5"
        cols="30"
        [(ngModel)]="remarks"
        [ngClass]="{
          disabled: isCompleted,
          'ng-invalid ng-dirty red-placeholder':
            selectedRange > 0 && selectedRange <= 2 && !remarks,
        }"
        [placeholder]="
          selectedRange > 0 && selectedRange <= 2
            ? 'Please let us know your concerns, comments and suggestions.'
            : ''
        "
        (ngModelChange)="onSelected(selectedRange)"
        pInputTextarea
      ></textarea>
    </div>

    <div
      style="
        display: flex;
        flex-direction: column;
        border: 1px solid lightgray;
        border-radius: 8px;
        padding: 16px;
        width: 30%;
        gap: 5px;
      "
    >
      <p class="poppins-400 font-12">Range</p>
      <p class="poppins-300 font-12" *ngFor="let range of metric.range">
        {{ range.range }} = {{ range.description }}
      </p>
    </div>
  </div>
</div>
