import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from './layout/app.layout.component';
import { NotfoundComponent } from './layout/notfound/notfound.component';
import { EvaluationFormComponent } from './modules/evaluation/evaluation-form/evaluation-form.component';
import { TemplateReadComponent } from './modules/evaluation/template-read/template-read.component';
import { TemplateCreateComponent } from './modules/templates/template-create/template-create.component';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: '',
              redirectTo: '/evaluation',
              pathMatch: 'full',
            },
            {
              path: 'evaluation',
              loadChildren: () =>
                import('./modules/evaluation/evaluation.module').then(
                  (m) => m.EvaluationModule
                ),
            },
            {
              path: 'evaluation/employee/:id',
              component: EvaluationFormComponent,
            },
            {
              path: 'performance',
              loadChildren: () =>
                import('./modules/performance/performance.module').then(
                  (m) => m.PerformanceModule
                ),
            },
            {
              path: 'templates',
              redirectTo: 'templates/list',
            },
            {
              path: 'templates/role/:id',
              component: TemplateReadComponent,
            },
            {
              path: 'templates/role/:id/edit',
              component: TemplateCreateComponent,
            },
            {
              path: 'templates',
              loadChildren: () =>
                import('./modules/templates/templates.module').then(
                  (m) => m.TemplatesdModule
                ),
            },
            {
              path: 'client',
              redirectTo: 'client/list',
            },
            {
              path: 'client',
              loadChildren: () =>
                import('./modules/clients/clients.module').then(
                  (m) => m.ClientsModule
                ),
            },
          ],
        },
        { path: 'notfound', component: NotfoundComponent },
        { path: '**', redirectTo: '/notfound' },
      ],
      {
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled',
        onSameUrlNavigation: 'reload',
      }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
