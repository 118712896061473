export const iconData = [
  {
    name: 'exceptional',
    path: 'assets/layout/images/range/exceptional.svg',
  },
  {
    name: 'above_average',
    path: 'assets/layout/images/range/above_average.svg',
  },
  {
    name: 'average',
    path: 'assets/layout/images/range/average.svg',
  },
  {
    name: 'below_average',
    path: 'assets/layout/images/range/below_average.svg',
  },
  {
    name: 'unacceptable',
    path: 'assets/layout/images/range/unacceptable.svg',
  },
  {
    name: 'unmark',
    path: 'assets/layout/images/evaluation/unmark.svg',
  },
  {
    name: 'comment',
    path: 'assets/layout/images/evaluation/comment.svg',
  },
  {
    name: 'dot',
    path: 'assets/layout/images/evaluation/dot.svg',
  },
  {
    name: 'dot_active',
    path: 'assets/layout/images/evaluation/dot_active.svg',
  },
  {
    name: 'dot_comment',
    path: 'assets/layout/images/evaluation/dot_comment.svg',
  },
  {
    name: 'grid',
    path: 'assets/layout/images/toggle/grid.svg',
  },
  {
    name: 'list',
    path: 'assets/layout/images/toggle/list.svg',
  },
  {
    name: 'edit',
    path: 'assets/layout/images/templates/edit.svg',
  },
  {
    name: 'view',
    path: 'assets/layout/images/templates/view.svg',
  },
  {
    name: 'duplicate',
    path: 'assets/layout/images/templates/duplicate.svg',
  },
  {
    name: 'delete',
    path: 'assets/layout/images/templates/delete.svg',
  },
  {
    name: 'remove',
    path: 'assets/layout/images/templates/remove.svg',
  },
];
