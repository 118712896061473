<div class="main-container">
  <div class="tab-container">
    <div
      class="tab-menu"
      *ngFor="let k of kpi; index as i"
      [ngClass]="{ active: activeTabName === kpi[i].name }"
      (click)="activeTab(kpi[i].name, kpi[i].id)"
    >
      <p>{{ kpi[i].name }} ({{ kpi[i].weight }}%)</p>
    </div>

    @if(isReady) {
    <div class="tag ml-auto">
      <div class="tag-content">
        <i class="pi pi-check-circle" style="color: currentColor"></i>
        <span style="margin-left: 5px">READY TO USE</span>
      </div>
    </div>
    }
  </div>

  <div class="form">
    <div
      class="flex flex-column gap-3"
      *ngFor="let goal of kpi[index]?.goals; index as goalIndex"
    >
      <p class="poppins-500 font-15 uppercase" style="color: #1f3062">
        {{ goal.name }}
      </p>
      <app-metric
        *ngFor="let metric of goal.metrics; index as metricIndex"
        [metric]="metric"
        [selectedRange]="getMetricRating(index, goalIndex, metricIndex)"
        [remarks]="getMetricRemarks(index, goalIndex, metricIndex)"
        (selected)="addMetric($event)"
        [isCompleted]="false"
      />
    </div>

    <app-comment
      [commentGroupName]="'Summary Comment'"
      [textArea1]="businessGoals"
      [textArea2]="personalGoals"
      (comments)="getSummaryComment($event)"
      [isCompleted]="false"
    />
    <app-comment
      [commentGroupName]="'Overall Comment'"
      [textArea1]="employeeStrengths"
      [textArea2]="opportunitiesForDevelopment"
      (comments)="getOverAllComment($event)"
      [isCompleted]="false"
    />
  </div>
</div>

<p-toast position="bottom-right" key="br" />
