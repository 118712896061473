import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';

import {
  EvaluationData,
  EvaluationResponseData,
  Metric,
  MetricRating,
} from 'src/app/services/evaluation/evaluation.model';
import { Employee } from 'src/app/services/employee/employee.model';
import { Kpi } from 'src/app/services/role/role.model';

import { EvaluationService } from 'src/app/services/evaluation/evaluation.service';
import { RoleService } from 'src/app/services/role/role.service';
import { EmployeeService } from 'src/app/services/employee/employee.service';

@Component({
  selector: 'app-evaluation-form',
  templateUrl: './evaluation-form.component.html',
  styleUrls: ['./evaluation-form.component.scss'],
  providers: [MessageService],
})
export class EvaluationFormComponent implements OnInit {
  activeTabName = 'People';
  status = 'MARK AS COMPLETE';
  isCompleted = false;
  toggle = false;

  visibleComplete = false;

  kpi: Kpi[] = [];
  metrics: Metric[] = [];
  metricRatings: MetricRating[] = [];

  employee: Employee | undefined;
  evaluationKpi!: EvaluationResponseData;

  index = 0;
  startDate!: Date;
  endDate!: Date;
  evaluationId = '';
  businessGoals = '';
  personalGoals = '';
  employeeStrengths = '';
  opportunitiesForDevelopment = '';
  evaluationStatus = '';

  peopleScore: number[] = [];
  clientScore: number[] = [];
  overAllRating = 0.0;

  roleService = inject(RoleService);
  employeeService = inject(EmployeeService);
  evaluationService = inject(EvaluationService);
  messageService = inject(MessageService);

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.getEmployee();
  }

  showBottomRight(detail: string, severity: string, summary: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      key: 'br',
      life: 3000,
    });
  }

  activeTab(tabName: string, id: string) {
    this.activeTabName = tabName;
    this.index = this.kpi.findIndex((kpi) => kpi.id === id);
  }

  onToggle(toggle: boolean) {
    this.toggle = !toggle;
  }

  onMarkAsComplete() {
    this.isCompleted = !this.isCompleted;
    if (this.isCompleted) {
      this.checkMMark();
    } else {
      this.status = 'MARK AS COMPLETE';
    }
  }

  checkMMark() {
    const unrated = this.metricRatings.every(
      (metric) => metric.rating === null
    );
    const someUrated = this.metricRatings.some(
      (metric) => metric.rating === null
    );

    if (unrated || someUrated) {
      this.showBottomRight('Ratings are not complete!', 'error', 'Error');
      this.isCompleted = !this.isCompleted;
    } else {
      this.showDialog(true);
    }
  }

  onComplete() {
    this.status = 'COMPLETED';
    this.onSubmit();
    this.showDialog(false);
  }

  addMetric(metric: Metric) {
    let index = this.metrics.findIndex((m) => m.id === metric.id);
    if (index === -1) {
      this.metrics.push(metric);
    } else {
      this.metrics[index] = metric;
    }
  }

  getMetricRating(
    index: number,
    goalIndex: number,
    metricIndex: number
  ): number {
    return this.evaluationKpi
      ? +this.evaluationKpi.role.kpi[index].goals[goalIndex].metrics[
          metricIndex
        ].rating
      : 0;
  }

  getMetricRemarks(
    index: number,
    goalIndex: number,
    metricIndex: number
  ): string {
    return this.evaluationKpi
      ? this.evaluationKpi.role.kpi[index].goals[goalIndex].metrics[metricIndex]
          .remarks
      : '';
  }

  getSummaryComment(comments: { comment1: string; comment2: string }) {
    this.businessGoals = comments.comment1;
    this.personalGoals = comments.comment2;
  }
  getOverAllComment(comments: { comment1: string; comment2: string }) {
    this.employeeStrengths = comments.comment1;
    this.opportunitiesForDevelopment = comments.comment2;
  }

  getEmployee() {
    const employeeId = this.route.snapshot.paramMap.get('id');

    if (employeeId) {
      this.employeeService.getEmployee(employeeId).subscribe({
        next: (responseData) => {
          const { data } = responseData;
          this.employee = data;
          this.getRole(data.role.id);
          console.log(this.metrics);
          if (data.latest_evaluation?.id) {
            this.evaluationId = data.latest_evaluation.id;
            this.getEvaluation(data.latest_evaluation.id);
          }
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }

  getRole(id: string) {
    if (id) {
      this.roleService.getRole(id).subscribe({
        next: (responseData) => {
          const { data } = responseData;
          this.kpi = data.kpi;
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }

  getEvaluation(id: string) {
    if (id) {
      this.evaluationService.getEvaluation(id).subscribe({
        next: (responseData) => {
          const { data } = responseData;
          this.startDate = new Date(data.start_date.iso);
          this.endDate = new Date(data.end_date.iso);
          this.businessGoals = data.business_goals;
          this.personalGoals = data.personal_goals;
          this.employeeStrengths = data.employee_strengths;
          this.opportunitiesForDevelopment = data.opportunities_for_development;
          this.evaluationStatus = data.status;
          this.evaluationKpi = data;
          this.metrics = [];
          data.role.kpi.map((kpi) => {
            kpi.goals.map((goal) => {
              goal.metrics.map((metric) => {
                this.metricRatings.push({
                  rating: metric.rating,
                });
                if (metric.rating) {
                  this.metrics.push({
                    id: metric.id,
                    rating: metric.rating,
                    remarks: metric.remarks,
                  });
                }
              });
            });
          });
          if (this.evaluationStatus === 'completed') {
            this.status = 'COMPLETED';
            this.isCompleted = false;
          }
          this.getOverAllScore();
          console.log(this.metrics);
        },
        error: (error) => {
          this.showBottomRight(`${error.error.message}`, 'error', 'Error');
        },
      });
    }
  }

  getPeopleScores() {
    let weight = 0;
    this.evaluationKpi?.role.kpi.map((kpi) => {
      if (kpi.name === 'People') {
        kpi.goals.map((goal) => {
          goal.metrics.map((metric) => {
            weight = kpi.weight;
            let score = (metric.rating * metric.weight!) / 100;
            this.peopleScore.push(+score.toFixed(2));
          });
        });
      }
    });

    let total = this.peopleScore.reduce((acc, num) => acc + num, 0);
    console.log(((total * weight) / 100).toFixed(2));
    return ((total * weight) / 100).toFixed(2);
  }

  getClientScores() {
    let weight = 0;
    this.evaluationKpi?.role.kpi.map((kpi) => {
      if (kpi.name === 'Client') {
        kpi.goals.map((goal) => {
          goal.metrics.map((metric) => {
            weight = kpi.weight;
            let score = (metric.rating * metric.weight!) / 100;
            this.clientScore.push(+score.toFixed(2));
          });
        });
      }
    });

    let total = this.clientScore.reduce((acc, num) => acc + num, 0);
    console.log(((total * weight) / 100).toFixed(2));
    return ((total * weight) / 100).toFixed(2);
  }

  getOverAllScore() {
    this.peopleScore = [];
    this.clientScore = [];
    this.overAllRating =
      Number(this.getPeopleScores()) + Number(this.getClientScores());
  }

  createEvaluation(data: EvaluationData) {
    this.evaluationService.createEvaluation(data).subscribe({
      next: (responseData) => {
        const { data } = responseData;
        this.evaluationId = data.id;
        this.getOverAllScore();
        this.getEvaluation(data.id);
        this.showBottomRight('Updated Successfully', 'success', 'Success');

        const unrated = this.metrics.every((metric) => metric.rating === null);

        setTimeout(() => {
          this.updateScore({
            id: this.evaluationId ? this.evaluationId : '',
            employee_id: this.employee?.id || '',
            role_id: this.employee?.role.id || '',
            start_date: this.startDate,
            end_date: this.endDate,
            business_goals: this.businessGoals,
            personal_goals: this.personalGoals,
            employee_strengths: this.employeeStrengths,
            opportunities_for_development: this.opportunitiesForDevelopment,
            score: this.overAllRating.toFixed(1),
            status:
              this.status === 'COMPLETED'
                ? 'completed'
                : unrated
                ? 'unrated'
                : 'draft',
            metric: this.metrics,
          });
        }, 2000);
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  updateScore(data: EvaluationData) {
    this.evaluationService.createEvaluation(data).subscribe({
      next: (responseData) => {},
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  onSubmit() {
    const unrated = this.metrics.every((metric) => metric.rating === null);
    const ratingValidation = this.metrics.some(
      (metric) => metric.rating <= 2 && metric.remarks === ''
    );

    if (!ratingValidation) {
      this.createEvaluation({
        id: this.evaluationId ? this.evaluationId : '',
        employee_id: this.employee?.id || '',
        role_id: this.employee?.role.id || '',
        start_date: this.startDate,
        end_date: this.endDate,
        business_goals: this.businessGoals,
        personal_goals: this.personalGoals,
        employee_strengths: this.employeeStrengths,
        opportunities_for_development: this.opportunitiesForDevelopment,
        score: this.overAllRating.toFixed(1),
        status:
          this.status === 'COMPLETED'
            ? 'completed'
            : unrated
            ? 'unrated'
            : 'draft',
        metric: this.metrics,
      });
    } else {
      this.showBottomRight(`Remarks are required!`, 'error', 'Error');
    }
  }

  showDialog(isVisible: boolean) {
    this.visibleComplete = isVisible;
    this.isCompleted = !this.isCompleted;
  }
}
