import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent {
  @Input({ required: true }) commentGroupName!: string;
  @Input({ required: true }) textArea1: string = '';
  @Input({ required: true }) textArea2: string = '';
  @Input({ required: true }) isCompleted!: boolean;
  @Output() comments = new EventEmitter<{
    comment1: string;
    comment2: string;
  }>();

  isOpen = false;
  // textArea1 = '';
  // textArea2 = '';

  onToggle() {
    this.isOpen = !this.isOpen;
  }

  getValue() {
    this.comments.emit({
      comment1: this.textArea1,
      comment2: this.textArea2,
    });
  }
}
