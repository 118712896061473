import { Component, Input, OnInit, inject } from '@angular/core';

import { MessageService } from 'primeng/api';

import {
  DuplicateRequest,
  ClientOptions,
} from 'src/app/services/client/client.model';

import { ClientService } from 'src/app/services/client/client.service';
import { RoleService } from 'src/app/services/role/role.service';

@Component({
  selector: 'app-duplicate-template-list',
  templateUrl: './duplicate-template-list.component.html',
  styleUrls: ['./duplicate-template-list.component.scss'],
  providers: [MessageService],
})
export class DuplicateTemplateListComponent implements OnInit {
  @Input({ required: true }) roleId?: string | null;

  clients: ClientOptions[] = [];
  selectedClient!: ClientOptions;
  role: string = '';

  applyToClients: DuplicateRequest = {
    role_id: '',
    role: [],
  };

  errorMessage = '';

  clientService = inject(ClientService);
  roleService = inject(RoleService);
  messageService = inject(MessageService);

  ngOnInit(): void {
    this.getClientList();
  }

  showBottomRight(detail: string, severity: string, summary: string) {
    this.messageService.add({
      severity: severity,
      summary: summary,
      detail: detail,
      key: 'br',
      life: 3000,
    });
  }

  getClientList() {
    this.clientService.getClientList().subscribe({
      next: (responseData) => {
        this.clients = [];
        responseData.data.map((data) => {
          this.clients.push({ name: data.name, code: data.id });
        });
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }

  getClientName(clientId: string) {
    const clientName = this.clients.find((client) => client.code === clientId);
    return clientName?.name;
  }

  addClientRole() {
    if (!this.roleId) {
      this.showBottomRight(
        'Please select a role template to duplicate',
        'error',
        'Error'
      );
      return;
    }

    if (!this.role || !this.selectedClient) {
      this.errorMessage = 'Client and role are required!';
      return;
    }

    if (this.roleId && this.role && this.selectedClient) {
      this.applyToClients.role_id = this.roleId;
      this.applyToClients.role.push({
        client_id: this.selectedClient.code,
        title: this.role,
      });
      this.role = '';
      this.errorMessage = '';
      return;
    }
  }

  removeClientRole(clientRoleIndex: number) {
    this.applyToClients.role_id = '';
    this.applyToClients.role.splice(clientRoleIndex, 1);
  }

  onDuplicateRole() {
    this.roleService.duplicateRole(this.applyToClients).subscribe({
      next: (responseData) => {
        this.showBottomRight('Successfully Applied', 'success', 'Success');
      },
      error: (error) => {
        this.showBottomRight(`${error.error.message}`, 'error', 'Error');
      },
    });
  }
}
